// Components
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import MdiInformationOutline from 'vue-material-design-icons/InformationOutline.vue'
import AlignVerticalModifier from '@/components/standalone/align-vertical-modifier/Align-vertical-modifier.vue'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-column'

// Vue@Properties
const props = {
  mjml: Object
}
// Vue@Properties

// Vue@Components
const components = {
  custColorPicker,
  AlignVerticalModifier,
  MdiInformationOutline
}
// Vue@Components

// Vue component syntax
export default {
  name,
  props,
  components
}
