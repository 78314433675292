export default {
  options: [
    {
      value: 'top',
      icon: 'mdi-format-vertical-align-top'
    },
    {
      value: 'middle',
      icon: 'mdi-format-vertical-align-center'
    },
    {
      value: 'bottom',
      icon: 'mdi-format-vertical-align-bottom'
    }
  ]
}
