// Component states
import states from './align-vertical-modifier.state.js'
import MdiFormatVerticalAlignBottom from 'vue-material-design-icons/FormatVerticalAlignBottom'
import MdiFormatVerticalAlignCenter from 'vue-material-design-icons/FormatVerticalAlignCenter'
import MdiFormatVerticalAlignTop from 'vue-material-design-icons/FormatVerticalAlignTop'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Align-vertical-modifier'

// Properties
const props = {
  mjml: Object,
  attribute: String,
  customKey: String,
  isText: Boolean
}

// Subcomponents
const components = {
  MdiFormatVerticalAlignBottom,
  MdiFormatVerticalAlignCenter,
  MdiFormatVerticalAlignTop
}

// Data
const data = function () {
  return {
    states
  }
}

// Methods
const methods = {

  /**
   * Callback called when one of the button from the alignment control is pressed
   * @param {String} alignment
   */
  onAlignButtonClicked (alignment) {
    if (!this.attribute) {
      this.mjml.attributes[this.customKey || 'vertical-align'] = alignment

      return
    }

    this.$emit('update', alignment)
  },

  /**
   * Check if the current align value is active
   * @param  {Object}  option (current option)
   *
   * @return {Boolean}
   */
  isActive (option) {
    const currentValue = this.mjml
      ? this.mjml.attributes[this.customKey || 'vertical-align']
      : this.attribute

    return option.value === currentValue
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  components
}
