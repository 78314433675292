import { render, staticRenderFns } from "./settings-column-modifier.html?vue&type=template&id=df5c2b8e&scoped=true&external"
import script from "./settings-column-modifier.script.js?vue&type=script&lang=js&external"
export * from "./settings-column-modifier.script.js?vue&type=script&lang=js&external"
import style0 from "./settings-column-modifier.scss?vue&type=style&index=0&id=df5c2b8e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df5c2b8e",
  null
  
)

/* custom blocks */
import block0 from "./settings-column-modifier.i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fstandalone%2Fsettings-column-modifier%2FSettings-column-modifier.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports